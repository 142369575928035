import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import messenger from '../images/messenger.png'
import mail from '../images/mail.png'

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <div className="container">
    <h1 className="PageH1">Contact us</h1>
    <p>We will help you turn your ideas to reality. Send us a message and we will reply to you shortly.</p>

    <form name="contact" method="post" data-netlify="true" action="/">
      <input type="hidden" name="form-name" value="contact" />
      <input name="name" placeholder="Name" type="text"/>
      <input name="email" placeholder="Email adress" type="email"/>
      <textarea name="message" placeholder="Message..."></textarea>
      <button style={{fontSize: '18px', height: '37px', width: '85px'}} type="submit">Send</button>
    </form>

    <h2>Other ways to connect?</h2>
    <p>Send us a message on one of our other platforms.</p>
    <a href="mailto:contact@cynesige.net"><img style={{margin: '0 15px 0'}} height="50" src={mail} alt="Email"></img></a>
    <a href="https://m.me/cynesige"><img style={{margin: '0 15px 0'}} height="50" src={messenger} alt="Facebook messenger"></img></a>
    
    </div>
    
  </Layout>
)

export default ContactPage
